<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <span class="col-lg-6 col-md-6 col-sm-6 m-t-5 d-inline-block"
          >{{$t('recent-emails-components.title')}}</span
        >
        <div class="col-lg-6 col-md-6 col-sm-6 text-right text-left-m">
          <router-link class="btn btn-primary" to="/mail/history"
            >{{$t('sms.dashboard.lbl-show-history')}}</router-link
          >
        </div>
      </div>
    </div>
    <div class="card-body block-el">
      <div v-if="loaded" class="responsive-table">
        <table  class="table">
          <thead class="bg-light">
            <tr>
              <th scope="col">{{$t('generic-str.subject')}}</th>
              <th scope="col">{{$t('generic-str.date')}}</th>
              <th scope="col">{{$t('generic-str.lbl-recipient')}}</th>
              <th scope="col">{{$t('generic-str.status.lbl-delivered')}}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr
                v-for="(message, i) in messages"
                :key="message.id">
              <td class="text-left"><span class="block" style="padding-left:15px">{{ message.subject}}</span></td>
              <td>{{ message.created_at | formatDate}}</td>
              <td class="text-left">
                <div class="max-200"
                  v-tooltip.top="`${message.to}`">{{ message.to}}</div>
              </td>
              <td data-label="Status">
                <span v-if="message.status == 'accepted'" class="badge badge-light">{{$tc('generic-str.status.lbl-pending', 1)}}</span>
                <span
                  v-else-if="message.status == 'queued'"
                  class="badge badge-secondary"
                >{{$t('generic-str.status.lbl-queue')}}</span>
                <span
                  v-else-if="message.status == 'sending'"
                  class="badge badge-accent"
                >{{$t('generic-str.status.lbl-sending')}}</span>
                <span v-else-if="message.status == 'sent'" class="badge badge-info">{{$t('generic-str.status.lbl-sent')}}</span>
                <span v-else-if="message.status == 'failed'" class="badge badge-danger">{{$t('generic-str.status.lbl-failure')}}</span>
                <span
                  v-else-if="message.status == 'delivered'"
                  class="badge badge-success"
                >{{$t('generic-str.status.lbl-delivered')}}</span>
                <span
                  v-else-if="message.status == 'undelivered'"
                  class="badge badge-dark"
                >{{$t('generic-str.status.lbl-unavailable')}}</span>
                <span
                  v-else-if="message.status == 'received'"
                  class="badge badge-success"
                >{{$tc('generic-str.status.lbl-response', 1)}}</span>
              </td>
              <td class="text-right">
                <div class="dropdown" style="padding-right:10px;">
                  <a role="button" class="dropdown-action" :to="'#menu-dropdown-'+i" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="icon dripicons-gear text-success"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" :id="'menu-dropdown-'+i" x-placement="bottom-end" style="">
                    <a class="dropdown-item" @click="goReport(message)">{{$tc('generic-str.detail', 2)}}</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="qt-block-ui" />
    </div>
  </div>
</template>

<script>
import MailService from '@/services/mail.service';

export default {
  name: 'RecentCard',
  data() {
    return {
      loaded: false,
      messages: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    goReport(message) {
      this.$router.push({ name: 'MailReport', params: { m: message } });
    },
    fetch() {
      MailService.getMessages({
        limit: '10',
      }).then(
        (response) => {
          console.log(response.data);
          this.loaded = true;
          this.messages = response.data;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.card-body {
  min-height: 300px;
}
tr {
  cursor: pointer;
  &:hover {
    background-color: #fafafa; /* #f0f6ff */
  }
}
</style>

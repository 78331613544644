<template>
  <div class="content">
    <PageHeader title="Email Dashboard" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-header">
              <span class="m-t-5 d-inline-block">Emails</span>
              <ul
                class="nav nav-pills nav-pills-primary float-right"
                id="pills-demo-sales"
                role="tablist"
              >
                <li class="nav-item">
                  <select v-model="messageDays" class="form-control">
                    <option value="7">{{$t('sms.dashboard.messages.days.7')}}</option>
                    <option value="30">{{$t('sms.dashboard.messages.days.30')}}</option>
                  </select>
                </li>
              </ul>
            </div>
            <div class="card-body block-el">
              <messages-chart :days="messageDays" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <sms-recent-card />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import MessagesChart from '@/components/mails/MessagesChart.vue';
import SmsRecentCard from '@/components/mails/RecentCard.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    MessagesChart,
    SmsRecentCard,
  },
  data() {
    return {
      messageDays: 7,
      carrierDays: 7,
    };
  },
};
</script>

<template>
  <div class="block-el">
    <line-chart v-if="fetched" :chartdata="messages" :options="options" :height="100" />
    <div v-else class="qt-block-ui" />
  </div>
</template>

<script>
import MailService from '@/services/mail.service';
import LineChart from '../LineChart.vue';

export default {
  name: 'MessageChart',
  components: { LineChart },
  props: ['days'],
  data() {
    return {
      fetched: false,
      messages: {},
      options: {
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                precision: 0,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
              },
            },
          ],
        },
      },
    };
  },
  watch: {
    days: 'fetch',
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.fetched = false;
      MailService.chart(this.days)
        .then(
          (response) => {
            console.log(response);
            const x = response;
            const object = JSON.stringify(x);
            let data = JSON.stringify(object); // this is a string
            data = data.replace('Saída', this.$t('chart.output'));
            data = data.replace('Entrada', this.$t('chart.input'));
            const converted = JSON.parse(JSON.parse(data));
            this.messages = converted;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.block-el {
  min-height: 200px;
}
</style>
